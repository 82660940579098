import React, { Component, Fragment } from "react";
import Helmet from "react-helmet";
import { Route, Switch, Redirect } from "react-router-dom";
import MainContainer from "./main-container";
import DrawerContainer from "./drawer";
import InfoCardContainer from "./info-card";
import TimelineYearsContainer from "./timeline-years";
import TimelineMonthsContainer from "./timeline-months";
import PhotosContainer from "./photos";
import DocumentsContainer from "./documents";
import PressContainer from "./press";
import MemorabiliaContainer from "./memorabilia";
import DrawerFront from "./drawer-front";
import AlbumModal from "./album-modal";
import PPVModal from "./news/ppv/ppv-modal";
import ModalWrapper from "./performance-log/modal-wrapper";
import ItineraryModal from "./performance-log/itinerary-notes";
import Map from "./map";
import GlobalMenu from "./global-menu";
import VideoPlayerContainer from "./video-player";
import LiveStreamContainer from "./live";
import LiveStreamTeaserContainer from "./live-teaser";
import GettingStartedContainer from "./getting-started";
import SearchContainer from "./search/search";
import SearchRecordStoreDayContainer from "./search-record-store-day";
import NewsArticle from "./news/article";
import PPV_Page from "./news/ppv/ppv";
import NewsletterContainer from "./newsletter";
import ContactContainer from "./contact";
import AccountInfo from "./account-info/index";
import MovieNightHome from "./movie-night/home/home";
import MovieNight from "./movie-night/movie-night";
import Page404 from "./404.js";
import TestContainer from "./test";
import { Provider, connect } from "react-redux";
import { showLiveStream, showLiveStreamTeaser } from "./toggleLiveStreams";
import HOCRequireAuth from "./hoc-require-auth";
import RequestModal from "./performance-log/requestModal";
import Player from "./components/new-player/new-player";
import NewspaperPage from "./newspaper-iframe/page";
import ContentfulPageContainer from "./contentful-pages/page";
import { withRouter } from "react-router-dom"

const renderAuthComponent = (Component) => (props) => {
  const AuthComponent = HOCRequireAuth(Component);
  return <AuthComponent {...props} />;
};

const mapContentfulPages = (pages) => {
  return pages.map((page) => {
    return (
      <Route
        key={page._id}
        path={page.routePath}
        render={(props) => <ContentfulPageContainer page={page} {...props} />}
      />
    );
  });
};

const MainRoutes = ({ pages, location }) => {
  const background = (location.state && location.state.modal) ? true : false;
  const backgroundLocation = background ? location.state.prevLocation : null;

  return (
    <MainContainer>
      <Switch location={background ? backgroundLocation : location}>
        <Route exact path="/welcome" component={DrawerFront} />
        <Route
          path="/video"
          render={renderAuthComponent(VideoPlayerContainer)}
        />
        {showLiveStream && (
          <Route
            path="/live"
            render={renderAuthComponent(LiveStreamContainer)}
          />
        )}
        {showLiveStreamTeaser && (
          <Route
            path="/live"
            render={renderAuthComponent(LiveStreamTeaserContainer)}
          />
        )}
        <Route path="/info-card/photos" component={PhotosContainer} />
        <Route path="/info-card/documents" component={DocumentsContainer} />
        <Route path="/info-card/press" component={PressContainer} />
        <Route path="/info-card/memorabilia" component={MemorabiliaContainer} />
        <Route path="/account" component={AccountInfo} />
        <Route path="/drawer" component={DrawerContainer} />
        <Route path="/map" component={Map} />
        <Route path="/menu" component={GlobalMenu} />
        <Route
          path="/:youtube/info-card"
          render={(props) => <InfoCardContainer {...props} />}
        />
        <Route path="/info-card" component={InfoCardContainer} />
        <Route path="/timeline" component={TimelineYearsContainer} />
        <Route path="/timeline-months" component={TimelineMonthsContainer} />
        <Route path="/album" component={AlbumModal} />
        <Route path="/playlist" component={AlbumModal} />
        <Route path="/film" component={AlbumModal} />
        <Route path="/request-modal" component={RequestModal} />
        <Route
          path="/performance-log/:tour"
          render={(props) => (
            <ModalWrapper {...props}>
              <ItineraryModal {...props} />
            </ModalWrapper>
          )}
        />
        <Route path="/performance-log" component={ModalWrapper} />
        <Route path="/movie-night/home" component={MovieNightHome} />
        <Route path="/movie-night/movietone" component={PPV_Page} />
        <Route
          path="/movie-night/:id?/:id?"
          render={(props) => <MovieNight {...props} />}
        />
        <Route
          path="/movietone/:id?"
          render={(props) => <PPVModal {...props} />}
        />

        <Route path="/news/top-40-tracks" render={NewspaperPage} />
        <Route path="/news/top-10-albums" render={NewspaperPage} />
        <Route path="/news/:page/article" render={NewspaperPage} />
        <Route path="/news/:page" component={NewspaperPage} />
        <Route path="/news" render={NewspaperPage} />
        <Route path="/news-article" component={NewsArticle} />
        <Route path="/newsletter" component={NewsletterContainer} />
        <Route path="/getting-started" component={GettingStartedContainer} />
        <Route path="/contact" component={ContactContainer} />
        <Route path="/search" component={SearchContainer} />
        <Route path="/find-store" component={SearchRecordStoreDayContainer} />
        <Route path="/404" component={Page404} />
        <Route path="/test" component={TestContainer} />
        <Route path="/embed" component={Player} />
        <Route path="/google71d6b4927829901b.html" render={() => null} />
        {pages && mapContentfulPages(pages)}
        <Route render={() => <Page404 />} />
      </Switch>

      {background && (
        <div className="overlay">
          <Switch location={location}>
            <Route
              path="/video"
              render={renderAuthComponent(VideoPlayerContainer)}
            />
            <Route path="/info-card/photos" component={PhotosContainer} />
            <Route path="/info-card/documents" component={DocumentsContainer} />
            <Route path="/info-card/press" component={PressContainer} />
            <Route
              path="/info-card/memorabilia"
              component={MemorabiliaContainer}
            />
            <Route path="/account" component={AccountInfo} />
            <Route path="/menu" component={GlobalMenu} />
            <Route
              path="/:youtube/info-card"
              render={(props) => <InfoCardContainer {...props} />}
            />
            <Route path="/info-card" component={InfoCardContainer} />
            <Route path="/album" component={AlbumModal} />
            <Route path="/playlist" component={AlbumModal} />
            <Route path="/film" component={AlbumModal} />
            <Route path="/request-modal" component={RequestModal} />
            <Route path="/performance-log/:tour" component={ItineraryModal} />
            <Route path="/performance-log" component={ModalWrapper} />
            <Route path="/movie-night/home" component={MovieNightHome} />
            <Route path="/movie-night/movietone" component={PPV_Page} />
            <Route
              path="/movie-night/:id?/:id?"
              render={(props) => <MovieNight {...props} />}
            />
            <Route
              path="/movietone/:id?"
              render={(props) => <PPVModal {...props} />}
            />
            <Route path="/newsletter" component={NewsletterContainer} />
            <Route
              path="/getting-started"
              component={GettingStartedContainer}
            />
            <Route path="/contact" component={ContactContainer} />
            <Route path="/search" component={SearchContainer} />
            <Route
              path="/find-store"
              component={SearchRecordStoreDayContainer}
            />
          </Switch>
        </div>
      )}
    </MainContainer>
  );
};

const NewspaperRoutes = () => (
  <Switch>
    <Route path="/times-contrarian/top-40-tracks" component={NewspaperPage} />
    <Route path="/times-contrarian/:page/article" component={NewspaperPage} />
    {/* <Route path="/times-contrarian/:page" component={NewspaperPage} /> */}
    <Route exact path="/times-contrarian" component={NewspaperPage} />
  </Switch>
);

const AppRouter = ({ pages, location }) => {
  const currentLocation = window.location.pathname;
  if (currentLocation === "/embed") {
    return <Route path="/embed" component={Player} />;
  }
  return (
    <Switch>
      <Redirect exact from="/" to="/welcome" />
      {/* <Route path="/times-contrarian" component={NewspaperRoutes} /> */}
      <Route
        path="/"
        render={() => (
          <MainRoutes 
            pages= { pages.filter((page) => !page.hidden) }
            location = {location} />
        )}
      />
    </Switch>
  );
};

class AppRoutes extends Component {
  render() {
    const { store, metas, pages, location } = this.props;
    return (
      <Provider store={store}>
        <Fragment>
          <Helmet {...metas} />
            <AppRouter pages={pages} location = {location}  />
        </Fragment>
      </Provider>
    );
  }
}

const mapStateToProps = function (state) {
  const {
    metas,
    pages: { all },
  } = state;
  return { metas, pages: all };
};

export default withRouter(connect(mapStateToProps)(AppRoutes));
